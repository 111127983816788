import React from "react";
import { BaseLayout } from "../components/Layout";
import { graphql } from "gatsby";
import { InfoSection } from "../components/Sections";

const about = ({ data }) => {
  return (
    <BaseLayout page="about" leftBox="contact" rightBox="team">
      <InfoSection cardData={data.allValuesTextJson.edges} />
    </BaseLayout>
  );
};

export default about;

export const query = graphql`
  {
    allValuesTextJson {
      edges {
        node {
          heading
          image {
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                formats: [AUTO, WEBP]
                quality: 95
                aspectRatio: 1
              )
            }
          }
          reversed
          text
        }
      }
    }
  }
`;
